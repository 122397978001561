'use strict'

###*
 # @ngdoc service
 # @name mundoAdmin.units.factory:UnitContactType

 # @description

###
angular
  .module 'mundoAdmin.units'
  .factory 'UnitContactType', [
    'MundoDefaultManager'
    (MundoDefaultManager)->
      UnitContactTypeBase = new MundoDefaultManager()
      UnitContactTypeBase.setUrl('units/contacts/types')

      UnitContactTypeBase
  ]
